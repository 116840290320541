.first-alert {
  margin-bottom: 0;
}
.card-img-top {
  min-height: 350px;
  max-height: 350px;
}
.card-body {
  min-height: 160px;
  max-height: 160px;
}
.container {
  margin-bottom: 20px;
}


@media (max-width: 576px) {
  /* Estilos para pantallas con un ancho máximo de 576px */
  body {
    font-size: 12px;
  }
  h1 {
    font-size: 14px;
  }
  .title {
    margin: 1em 0;
    text-align: center;
  }
  .container_logo {
    display: flex;
    justify-content: center;
  }
  .container_logo img {
    width: 20px;
    height: 20px;
  }
  .card  {
    max-height: 670px!important;
  }
  /* Puedes añadir más estilos específicos aquí */
}
.card{
  margin-bottom: 1em;
}
a .container_logo img {
  width: 40px;
  height: 40px;
}
.card-body  {
  min-height: 210px;
}
.button_wsp {
  padding: 10px 20px;
  background-color: #25D366;
   border-radius: 5px;
}
.button_wsp a{
  color: white; text-decoration: none;
}
.desc {
  min-height: 100px;
}
.bi-whatsapp {
  color: white;
  margin-right: 5px;
  margin-bottom: 1px;
}